import * as React from "react";
import "../styles/global.css";
import GatsbyImage from "gatsby-image";
import {Link} from "gatsby";

const CategoryCard = (props) => {

  const result = props.type === "horizontal" ?
    (
      <Link to={props.slug}>
        <div className="bg-white rounded-xl hover-move-up shadow flex-grow">
          <div className="flex justify-center p-4">
            <GatsbyImage fixed={props.icon}></GatsbyImage>
          </div>
          <h1 className="text-center pb-4">{props.category}</h1>
        </div>
      </Link>
    )
    :
    (
      <Link to={props.slug}>
        <div className="flex bg-white rounded-xl hover-move-up shadow my-4 px-2 pb-2 items-center">
          <GatsbyImage fixed={props.icon}></GatsbyImage>
          <h1 className="flex-grow text-center">{props.category}</h1>
        </div>
      </Link>
    )
  ;

  return result;

}

export default CategoryCard;