import * as React from "react";
import "../styles/global.css";
import Layout from "../layout/layout";
import {useStaticQuery, graphql} from "gatsby";
import CategoryCard from "../components/categorycard";

const BlogPage = () => {
  const categories = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "category"}}}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              icon {
                childImageSharp {
                  fixed(width: 80, height: 80) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const categoriesCards = categories.allMarkdownRemark.edges.map( edge => {
    return(
      <CategoryCard
        slug={edge.node.fields.slug}
        icon={edge.node.frontmatter.icon.childImageSharp.fixed}
        category={edge.node.frontmatter.title}
        type="horizontal">
      </CategoryCard>
    )
  });

  return(
    <Layout>
      <div className="animate-fadeIn">
        <div className="grid grid-cols-3 gap-4 my-8">
          {categoriesCards}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage;